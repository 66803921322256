var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"instructors"},[_c('div',{staticClass:"modal fade",attrs:{"id":"deleteRecordModal","tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.saveDeleteRecord}},[_vm._v(" Yes ")]),_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" No ")])])])])]),_vm._m(2),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_c('router-link',{attrs:{"to":{ path: '/marketplace/categorize/add' }}},[_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"}},[_vm._v(" Add ")])])],1)])]),_c('tbody',_vm._l((_vm.categorize),function(assignment,index){return _c('tr',{key:assignment.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(assignment.category))]),_c('td',[_vm._v(_vm._s(assignment.title))]),_c('td',[_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.confirmDeleteRecord(
                $event,
                assignment.category_id,
                assignment.product_id,
                index
              )}}},[_vm._v(" Delete ")])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(" Confirm you would like to delete this category! ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-body"},[_c('p',[_vm._v(" Are you sure you would like to delete this category? ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"},[_c('h1',{staticClass:"h2"},[_vm._v("Assign Categories")])])
}]

export { render, staticRenderFns }